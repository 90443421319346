import React, { FC, forwardRef, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Text,
  TextTypography,
  Button,
  ButtonPriority,
  DatePickerInput,
  ButtonSize,
  ThreeDotsLoader,
  Divider,
} from 'wix-ui-tpa/cssVars';
import { PlanCustomization } from '@wix/ambassador-pricing-plans-plan-customization-v1-plan-customization/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { FormValues, FormError, FormViewerHandle } from '@wix/form-viewer/widget';
import { FormViewer } from '@wix/form-viewer/widget';
import { IUser, useTranslation } from '@wix/yoshi-flow-editor';
import { PLAN_CUSTOMIZATION_HOOKS } from '../hooks';
import { st, classes } from './PlanCustomizationWidget.st.css';
import { PlanPreview } from './PlanPreview/PlanPreview';

export type PlanCustomizationProps = {
  isLoading: boolean;

  // Data
  plan: PublicPlan;
  customizedPlan?: PlanCustomization | null;
  user: {
    email?: string;
    role: IUser['role'];
  } | null;

  // Auth actions
  promptLogin: () => void;
  promptSignup: () => void;

  // PlanCustomization
  setPlanStartDate: (customizedPlan: PlanCustomization, date: Date) => Promise<void>;
  proceedToPayment: (customizedPlan: PlanCustomization, formValues?: FormValues) => Promise<void>;

  isCheckoutInProgress: boolean;
};

const ProductPageWidget: React.FC<PlanCustomizationProps> = (props) => {
  const { plan } = props;
  if (!plan) {
    // TODO: Add loader
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <header>
          <Text tagName="h1" typography={TextTypography.largeTitle}>
            {plan.name}
          </Text>
          <Text typography={TextTypography.runningText}>Complete the details for your selected plan</Text>
        </header>
        <Divider className={classes.headerDivider} />
        <main>
          <ProductCustomizationForm {...props} />
        </main>
      </div>
      <aside className={classes.previewContainer}>
        <PlanPreview {...props} />
      </aside>
    </div>
  );
};

const ProductCustomizationForm: FC<PlanCustomizationProps> = (props) => {
  const formViewerRef = useRef<FormViewerHandle>(null);
  const [formValues, setFormValues] = useState<FormValues>({});

  const onProceedToPayment = async () => {
    if (formViewerRef.current) {
      const isValid = await formViewerRef.current.validate();
      if (!isValid) {
        return;
      }
    }

    props.proceedToPayment(props.customizedPlan!, isEmpty(formValues) ? undefined : formValues);
  };

  if (!props.user) {
    return <LoginButtons {...props} />;
  }

  const loading = props.isCheckoutInProgress;

  return (
    <div>
      {props.plan.allowFutureStartDate && <PlanStartDate {...props} />}
      {props.plan.formId && (
        <AdditionalDetails {...props} formValues={formValues} setFormValues={setFormValues} ref={formViewerRef} />
      )}
      {props.customizedPlan && (
        <Button
          size={ButtonSize.large}
          className={st(classes.ctaButton, { loading })}
          fullWidth
          upgrade
          priority={ButtonPriority.primary}
          onClick={onProceedToPayment}
          disabled={loading}
        >
          <span className={classes.label}>Proceed to payment</span>
          {loading && (
            <div className={classes.loaderContainer}>
              <ThreeDotsLoader className={classes.loader} />
            </div>
          )}
        </Button>
      )}
    </div>
  );
};

const AdditionalDetails = forwardRef<
  FormViewerHandle,
  PlanCustomizationProps & { formValues: FormValues; setFormValues: (values: FormValues) => void }
>(({ plan, formValues, setFormValues }, ref) => {
  const { i18n } = useTranslation();
  const [formErrors, setFormErrors] = useState<FormError[]>([]);

  return (
    <div>
      <Text tagName="div" className={classes.sectionTitle} typography={TextTypography.smallTitle}>
        Add your info
      </Text>
      <FormViewer
        ref={ref}
        i18n={i18n}
        onChange={(values) => setFormValues(values)}
        values={formValues}
        onValidate={(errors) => setFormErrors(errors)}
        errors={formErrors}
        formId={plan.formId!}
      />
    </div>
  );
});

const PlanStartDate: FC<PlanCustomizationProps> = (props) => {
  const [date, setDate] = useState<Date>(new Date());

  const handleOnChange = (value: string | Date) => {
    const _date = new Date(value);
    setDate(_date);
    props.setPlanStartDate(props.customizedPlan!, _date);
  };

  return (
    <div>
      <Text tagName="div" className={classes.sectionTitle} typography={TextTypography.smallTitle}>
        Customize the plan
      </Text>
      <DatePickerInput
        value={date}
        onChange={handleOnChange}
        label="Choose a date"
        excludePastDates
        data-hook={PLAN_CUSTOMIZATION_HOOKS.DATE_PICKER}
      />
    </div>
  );
};

const LoginButtons: FC<PlanCustomizationProps> = (props) => {
  const { promptLogin, promptSignup } = props;

  return (
    <div>
      <div className={classes.loginButtons}>
        <Button fullWidth onClick={() => promptLogin()}>
          Log in
        </Button>
        <Button fullWidth priority={ButtonPriority.basicSecondary} onClick={() => promptSignup()}>
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default ProductPageWidget;
