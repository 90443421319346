import React from 'react';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import BreakpointsProvider from '../../../hooks/BreakpointsProvider';
import { LocaleProvider } from '../../../hooks/useLocale';
import ProductPageWidget, { PlanCustomizationProps } from './PlanCustomizationWidget';

const BREAKPOINTS = {
  isNarrow: (width: number) => width > 0 && width <= 750,
  isExtraNarrow: (width: number) => width > 0 && width <= 450,
};

export interface RootProductPageProps extends PlanCustomizationProps {
  locale: string;
}

export default function ({ host, locale, ...props }: WidgetProps<RootProductPageProps>) {
  const { isMobile } = useEnvironment();
  const width = host.dimensions.width ?? (isMobile ? 320 : 980);
  return (
    <BreakpointsProvider breakpoints={BREAKPOINTS} defaultWidth={width}>
      <LocaleProvider locale={locale}>
        <FormViewerContext host={host} {...props}>
          <ProductPageWidget {...props} />
        </FormViewerContext>
      </LocaleProvider>
    </BreakpointsProvider>
  );
}
